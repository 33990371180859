<template>
  <main class="tourism page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <section class="filter">
      <div class="filter__item filter__item-departure">
        <label class="label filter__label">{{ $t("From") }}</label>
        <input class="input filter__input" type="text" v-model="filters.from" />
      </div>

      <div class="filter__item filter__item-arrival">
        <label class="label filter__label" for="to">{{
          $t("To")
        }}</label>
        <div class="dropdown">
          <select
            class="dropdown__select"
            v-model="filters['to']"
            data-filter="to"
            name="to"
            id="to"
          >
            <option
              v-for="option in filters_options['to']"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <button class="button dropdown__button">
            {{ $t("Select") }}
          </button>
          <div class="dropdown__list">
            <a
              v-for="option in filters_options['to']"
              class="link dropdown__option"
              href="#"
              :data-value="option.id"
              :key="option.id"
              >{{ option.name }}</a
            >
          </div>
        </div>
      </div>

      <div class="filter__item filter__item-date">
        <label class="label filter__label" for>{{ $t("Dates") }}</label>
        <date-range-picker v-model="filters.dateRange" class="range-datepicker">
          <!-- <div slot="footer">
          </div>-->
        </date-range-picker>
      </div>

      <div class="filter__item filter__item-people">
        <label class="label filter__label" for>{{
          $t("Passengers")
        }}</label>
        <div class="dropdown">
          <select
            class="dropdown__select"
            v-model="filters['passengers']"
            data-filter="passengers"
            name="passengers"
            id="passengers"
          >
            <option value="AA">2 {{ $t("Adults") }}</option>
            <option value="AAC">
              2 {{ $t("Adults") }} + {{ $t("Boy") }}
            </option>
            <option value="AACC">
              2 {{ $t("Adults") }} + 2 {{ $t("Children") }}
            </option>
          </select>
          <button class="button dropdown__button">
            {{ $t("Select") }}
          </button>
          <div class="dropdown__list">
            <a class="link dropdown__option" href="#" data-value="AA"
              >2 {{ $t("Adults") }}</a
            >
            <a class="link dropdown__option" href="#" data-value="AAC"
              >2 {{ $t("Adults") }} + {{ $t("Boy") }}</a
            >
            <a class="link dropdown__option" href="#" data-value="AACC"
              >2 {{ $t("Adults") }} + 2 {{ $t("Children") }}</a
            >
          </div>
        </div>
      </div>

      <div class="filter__item filter__item-budget">
        <label class="label filter__label" for>{{
          $t("Budget")
        }}</label>
        <div class="dropdown">
          <select
            class="dropdown__select"
            v-model="filters['budget']"
            data-filter="budget"
            name="budget"
            id="budget"
          >
            <option value="200" selected="selected">&#60;200</option>
            <option value="500">&#60;500</option>
            <option value="750">&#60;750</option>
            <option value="10000">&#60;10000</option>
          </select>
          <button class="button dropdown__button">
            {{ $t("Select") }}
          </button>
          <div class="dropdown__list">
            <a class="link dropdown__option" href="#" data-value="200"
              >&#60;200</a
            >
            <a class="link dropdown__option" href="#" data-value="500"
              >&#60;500</a
            >
            <a class="link dropdown__option" href="#" data-value="750"
              >&#60;750</a
            >
            <a class="link dropdown__option" href="#" data-value="10000"
              >&#60;10000</a
            >
          </div>
        </div>
      </div>

      <div class="filter__item filter__item-hospitality">
        <label class="label filter__label" for>{{
          $t("Degree of hospitality")
        }}</label>
        <div class="dropdown">
          <select
            class="dropdown__select"
            v-model="filters.stars"
            data-filter="stars"
            name="stars"
            id="stars"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <button class="button dropdown__button">
            {{ $t("Select") }}
          </button>
          <div class="dropdown__list">
            <a class="link dropdown__option" href="#" data-value="1">1</a>
            <a class="link dropdown__option" href="#" data-value="2">2</a>
            <a class="link dropdown__option" href="#" data-value="3">3</a>
            <a class="link dropdown__option" href="#" data-value="4">4</a>
            <a class="link dropdown__option" href="#" data-value="5">5</a>
          </div>
        </div>
      </div>

      <button class="button filter__button" @click="filter_deal()"></button>
    </section>

    <div class="tour-category-container">
      <div
        class="category__unit tourism__slider-unit mb-10"
        v-for="index in 12"
        :key="index"
      >
        <div class="category__bg @@class-bg">
          <div class="category__share">
            <div class="category__share-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.88"
                height="25"
                viewBox="0 0 21.88 25"
              >
                <defs:style>
                  .share { fill: #fff; }
                </defs:style>
                <path
                  class="share"
                  d="M17.188,15.625A4.675,4.675,0,0,0,13.682,17.2L9.019,14.287a4.7,4.7,0,0,0,0-3.574L13.682,7.8a4.648,4.648,0,1,0-.83-1.323L8.188,9.39a4.685,4.685,0,1,0,0,6.221l4.663,2.915a4.629,4.629,0,0,0-.352,1.787,4.688,4.688,0,1,0,4.683-4.688Zm0-14.063a3.125,3.125,0,1,1-3.125,3.125A3.128,3.128,0,0,1,17.188,1.563ZM4.688,15.625A3.125,3.125,0,1,1,7.813,12.5,3.128,3.128,0,0,1,4.688,15.625Zm12.5,7.813a3.125,3.125,0,1,1,3.125-3.125A3.128,3.128,0,0,1,17.188,23.438Z"
                />
              </svg>
            </div>

            <div class="category__socials">
              <a class="link category__social" href>
                <svg
                  class="category__svg category__whatsapp"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <defs:style>
                    .whatsapp { fill: #29abe2; }
                  </defs:style>
                  <path
                    class="whatsapp"
                    d="M25.507,36.359A14.871,14.871,0,0,0,2.109,54.3L0,62l7.882-2.069a14.821,14.821,0,0,0,7.1,1.808h.007A14.772,14.772,0,0,0,25.507,36.359ZM14.993,59.234A12.334,12.334,0,0,1,8.7,57.513l-.449-.268L3.576,58.471l1.246-4.56-.295-.469a12.38,12.38,0,1,1,22.962-6.569A12.494,12.494,0,0,1,14.993,59.234ZM21.77,49.98c-.368-.187-2.2-1.085-2.538-1.205s-.589-.187-.837.188-.958,1.205-1.179,1.46-.435.281-.8.094a10.111,10.111,0,0,1-5.056-4.42c-.382-.656.382-.609,1.092-2.029a.688.688,0,0,0-.033-.65c-.094-.187-.837-2.016-1.145-2.759-.3-.723-.609-.623-.837-.636s-.462-.013-.71-.013a1.376,1.376,0,0,0-.991.462,4.174,4.174,0,0,0-1.3,3.1,7.277,7.277,0,0,0,1.513,3.844,16.6,16.6,0,0,0,6.348,5.612c2.357,1.018,3.281,1.1,4.46.931a3.8,3.8,0,0,0,2.5-1.768,3.107,3.107,0,0,0,.214-1.768C22.386,50.254,22.138,50.161,21.77,49.98Z"
                    transform="translate(0 -32)"
                  />
                </svg>
              </a>

              <a class="link category__social" href>
                <svg
                  class="category__svg category__sms"
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="30"
                  viewBox="0 0 34 30"
                >
                  <defs:style>
                    .sms { fill: #29abe2; }
                  </defs:style>
                  <path
                    class="sms"
                    d="M20.3,41.643H19.224a1.066,1.066,0,0,0-.958.589L17.081,44.6,15.9,42.232a1.066,1.066,0,0,0-.958-.589H13.867A1.075,1.075,0,0,0,12.8,42.714v6.964a.537.537,0,0,0,.536.536H14.4a.537.537,0,0,0,.536-.536V45.112L16.6,48.848a.535.535,0,0,0,.958,0l1.661-3.737v4.567a.537.537,0,0,0,.536.536h1.071a.537.537,0,0,0,.536-.536V42.714A1.065,1.065,0,0,0,20.3,41.643ZM9.005,44.489a.343.343,0,0,1-.141-.254c0-.208.3-.442.7-.442h.824a.537.537,0,0,0,.536-.536V42.185a.537.537,0,0,0-.536-.536H9.568a2.723,2.723,0,0,0-2.839,2.585,2.487,2.487,0,0,0,.891,1.882l1.467,1.259a.343.343,0,0,1,.141.254c0,.208-.3.442-.7.442H7.706a.537.537,0,0,0-.536.536v1.071a.537.537,0,0,0,.536.536H8.53a2.723,2.723,0,0,0,2.839-2.585,2.487,2.487,0,0,0-.891-1.882L9.005,44.489Zm16.071,0a.343.343,0,0,1-.141-.254c0-.208.3-.442.7-.442h.824a.537.537,0,0,0,.536-.536V42.185a.537.537,0,0,0-.536-.536h-.824a2.723,2.723,0,0,0-2.839,2.585,2.487,2.487,0,0,0,.891,1.882l1.467,1.259a.343.343,0,0,1,.141.254c0,.208-.3.442-.7.442h-.817a.537.537,0,0,0-.536.536v1.071a.537.537,0,0,0,.536.536H24.6a2.723,2.723,0,0,0,2.839-2.585,2.487,2.487,0,0,0-.891-1.882ZM17.081,32C7.612,32-.062,38.234-.062,45.929A12.31,12.31,0,0,0,3.48,54.386a16.993,16.993,0,0,1-3.107,4.9A1.608,1.608,0,0,0,1.539,62a16.248,16.248,0,0,0,9.315-3.1,20.759,20.759,0,0,0,6.228.958c9.469,0,17.143-6.234,17.143-13.929S26.55,32,17.081,32Zm0,25.714a18.47,18.47,0,0,1-5.571-.857l-1.018-.321-.871.616A14.449,14.449,0,0,1,2.751,59.8a18.065,18.065,0,0,0,2.732-4.661l.475-1.252-.917-.978a10.143,10.143,0,0,1-2.953-6.984c0-6.5,6.73-11.786,15-11.786s15,5.29,15,11.786S25.351,57.714,17.081,57.714Z"
                    transform="translate(0.066 -32)"
                  />
                </svg>
              </a>

              <a class="link category__social" href>
                <svg
                  class="category__svg category__instagram"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <defs:style>
                    .instagram { fill: #29abe2; }
                  </defs:style>
                  <path
                    class="instagram"
                    d="M14.932,39.133a7.692,7.692,0,1,0,7.692,7.692A7.679,7.679,0,0,0,14.932,39.133Zm0,12.692a5,5,0,1,1,5-5,5.01,5.01,0,0,1-5,5Zm9.8-13.007a1.794,1.794,0,1,1-1.794-1.794A1.79,1.79,0,0,1,24.732,38.819Zm5.094,1.821A8.878,8.878,0,0,0,27.4,34.354a8.937,8.937,0,0,0-6.286-2.423c-2.477-.141-9.9-.141-12.378,0a8.924,8.924,0,0,0-6.286,2.417A8.907,8.907,0,0,0,.03,40.633c-.141,2.477-.141,9.9,0,12.378A8.878,8.878,0,0,0,2.454,59.3,8.948,8.948,0,0,0,8.74,61.72c2.477.141,9.9.141,12.378,0A8.878,8.878,0,0,0,27.4,59.3a8.937,8.937,0,0,0,2.423-6.286C29.967,50.534,29.967,43.116,29.826,40.64Zm-3.2,15.028a5.063,5.063,0,0,1-2.852,2.852c-1.975.783-6.661.6-8.843.6s-6.875.174-8.843-.6a5.063,5.063,0,0,1-2.852-2.852c-.783-1.975-.6-6.661-.6-8.843s-.174-6.875.6-8.843A5.063,5.063,0,0,1,6.089,35.13c1.975-.783,6.661-.6,8.843-.6s6.875-.174,8.843.6a5.063,5.063,0,0,1,2.852,2.852c.783,1.975.6,6.661.6,8.843S27.41,53.7,26.626,55.668Z"
                    transform="translate(0.075 -31.825)"
                  />
                </svg>
              </a>

              <a class="link category__social" href>
                <svg
                  class="category__svg category__facebook"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="30"
                  viewBox="0 0 16 30"
                >
                  <defs:style>
                    .facebook { fill: #29abe2; }
                  </defs:style>
                  <path
                    class="facebook"
                    d="M37.9,16.875l.833-5.429h-5.21V7.922a2.715,2.715,0,0,1,3.061-2.933h2.368V.367A28.882,28.882,0,0,0,34.753,0c-4.29,0-7.095,2.6-7.095,7.308v4.138H22.89v5.429h4.769V30h5.869V16.875Z"
                    transform="translate(-22.89)"
                  />
                </svg>
              </a>
            </div>
          </div>

          <div class="category__star">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26.12"
              height="25"
              viewBox="0 0 26.12 25"
            >
              <defs:style>
                .star-stroke { fill: #fff; }
              </defs:style>
              <path
                class="star-stroke"
                d="M45.282,8.361l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0L28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666Zm-5.971,7.148,1.357,7.909-7.109-3.73-7.1,3.735,1.357-7.909L22.062,9.9l7.943-1.152,3.554-7.2,3.554,7.2L45.057,9.9l-5.746,5.6Z"
                transform="translate(-20.5 0.013)"
              />
            </svg>
          </div>

          <div class="tourism-category__place">
            <div class="tourism-category__image-wrapper">
              <img
                class="tourism-category__image"
                src="/assets/img/rome2.png"
                alt
              />
            </div>
            <b class="tourism-category__place-name"> place name </b>
          </div>
        </div>

        <div class="category__info tourism-category__info">
          <p
            class="text category__text tourism-category__text tourism-catefory-title"
          >
            title
          </p>
          <div class="category__price-wrapper">
            <span class="category__price"
              >{{ $t("Price") }}: &nbsp;</span
            >
            <span class="direction-right">
              <b class="category__current">{{ $t("$") }}</b>
              <b class="category__price-value"> price value </b>
            </span>
          </div>
          <router-link
            :to="{
              name: 'TourismProduct',
              params: { id: 'id', rowId: 'rowId', destinationId: 'destination_id', dealType: 'dealType' },
            }"
            v-slot="{ href }"
          >
            <a
              :href="href"
              class="link category__button tourism-category__button"
              >{{ $t("Find") }} {{ $t("Dates") }}</a
            >
          </router-link>
        </div>
      </div>
    </div>

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import appConfig from "../appConfig";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  mixins: [validationMixin],
  name: "TourCategory",
  components: {
    DateRangePicker,
    Loading,
  },
  data: () => {
    // with all current filters values
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    return {
      // to make the fetch data process easier
      // lets define filters object

      // dateRange: {startDate, endDate},
      filters: {
        // date(val) {
        //   return val ? val.toLocaleString() : ''
        // },
        to: "",
        from: "TLV",
        passengers: "",
        budget: "",
        stars: "",
        deal_type: "NOFSHON",
        dateRange: { startDate, endDate },
      },
      dealsSearchInProgress: false,
      dealsSearchRan: false,
      reInitRecommendCarouselNeeded: false,
      reInitTourCheapestDealsCarouselNeeded: false,
      reInitNofshonCheapestDealsCarouselNeeded: false,
    };
  },
  validations: {},
  mounted() {
    let obj = this;
    obj.fetchFiltersOptions();

    moduleDropdown.init();
    moduleFavorites.init();
    moduleFilter.init();
    moduleModalBooking.init();
    moduleModalDiscount.init();
    moduleTourism.init();
    moduleTourismProd.init();
    moduleTrip.init();

    obj.dropdownHelperInit();
    obj.tourCheapestDealsCarouselInit();
    obj.nofshonCheapestDealsCarouselInit();
  },
  computed: {
    ...mapState({
      loading: (state) => state.deal.loading,
      destinations: (state) => state.destination.destinationData_receipts,
      deal_data: (state) => state.deal.dealData_receipts,
      cheapestDealsData: (state) => state.deal.cheapestDealsData,
    }),
    activeDestinations() {
      let activeDestinations = null;

      activeDestinations =
        this.destinations && Array.isArray(this.destinations)
          ? this.destinations.filter((destination) => destination.active)
          : [];

      return activeDestinations;
    },
    flightCheapestDeals() {
      let flightCheapestDeals = [];

      if (Array.isArray(this.cheapestDealsData)) {
        flightCheapestDeals = this.cheapestDealsData.filter(function (
          cheapestDeal
        ) {
          return cheapestDeal.dealType.toLowerCase() === "flight";
        });
      }

      return flightCheapestDeals;
    },
    nofshonCheapestDeals() {
      let nofshonCheapestDeals = [];

      if (Array.isArray(this.cheapestDealsData)) {
        nofshonCheapestDeals = this.cheapestDealsData.filter(function (
          cheapestDeal
        ) {
          return cheapestDeal.dealType.toLowerCase() === "nofshon";
        });
      }

      let obj = this;
      obj.reInitNofshonCheapestDealsCarouselNeeded = true;
      setTimeout(function () {
        if (obj.reInitNofshonCheapestDealsCarouselNeeded) {
          obj.reInitNofshonCheapestDealsCarouselNeeded = false;
          obj.reInitNofshonCheapestDealsCarousel();
        }
      }, 500);

      return nofshonCheapestDeals;
    },
    tourCheapestDeals() {
      let tourCheapestDeals = [];

      if (Array.isArray(this.cheapestDealsData)) {
        tourCheapestDeals = this.cheapestDealsData.filter(function (
          cheapestDeal
        ) {
          return cheapestDeal.dealType.toLowerCase() === "tour";
        });
      }

      let obj = this;
      obj.reInitTourCheapestDealsCarouselNeeded = true;
      setTimeout(function () {
        if (obj.reInitTourCheapestDealsCarouselNeeded) {
          obj.reInitTourCheapestDealsCarouselNeeded = false;
          obj.reInitTourCheapestDealsCarousel();
        }
      }, 500);

      return tourCheapestDeals;
    },
    // lets define filters_options object
    // to have all options for all filters
    // in the one place
    filters_options() {
      return {
        to: this.activeDestinations,
      };
    },
  },
  created() {
    if (this.deal_data == "") {
      const params = {};
      // this.getDeal({ filters: params });
    }
    this.getCheapestDeals();
  },
  destroyed() {
    this.dropdownHelperDestroy();
  },
  watch: {
    filters_options: {
      deep: true,
      handler(newValue, oldValue) {
        setTimeout(function () {
          moduleDropdown.init();
        }, 100);
      },
    },
    deal_data: {
      deep: true,
      handler(newValue, oldValue) {
        // if (!this.compareObjects(newValue, oldValue)) {
        let obj = this;
        obj.reInitRecommendCarouselNeeded = true;
        setTimeout(function () {
          if (obj.reInitRecommendCarouselNeeded) {
            obj.reInitRecommendCarouselNeeded = false;
            obj.reInitRecommendCarousel();
          }
        }, 500);
        // }
      },
    },
  },
  methods: {
    ...mapActions("destination", {
      getDestination: "getDestination",
    }),
    ...mapActions("deal", {
      getDeal: "getDeal",
      getCheapestDeals: "getCheapestDeals",
    }),
    getApiURL() {
      return appConfig.apiUrl;
    },
    compareObjects(o, p) {
      var i,
        keysO = Object.keys(o).sort(),
        keysP = Object.keys(p).sort();
      if (keysO.length !== keysP.length) {
        return false; //not the same nr of keys
      }
      if (keysO.join("") !== keysP.join("")) {
        return false; //different keys
      }
      for (i = 0; i < keysO.length; ++i) {
        if (o[keysO[i]] instanceof Array) {
          if (!(p[keysO[i]] instanceof Array)) {
            return false;
          }
          //if (this.compareObjects(o[keysO[i]], p[keysO[i]] === false) return false
          //would work, too, and perhaps is a better fit, still, this is easy, too
          if (p[keysO[i]].sort().join("") !== o[keysO[i]].sort().join("")) {
            return false;
          }
        } else if (o[keysO[i]] instanceof Date) {
          if (!(p[keysO[i]] instanceof Date)) {
            return false;
          }
          if ("" + o[keysO[i]] !== "" + p[keysO[i]]) {
            return false;
          }
        } else if (o[keysO[i]] instanceof Function) {
          if (!(p[keysO[i]] instanceof Function)) {
            return false;
          }
          //ignore functions, or check them regardless?
        } else if (o[keysO[i]] instanceof Object) {
          if (!(p[keysO[i]] instanceof Object)) {
            return false;
          }
          if (o[keysO[i]] === o) {
            //self reference?
            if (p[keysO[i]] !== p) {
              return false;
            }
          } else if (this.compareObjects(o[keysO[i]], p[keysO[i]]) === false) {
            return false; //WARNING: does not deal with circular refs other than ^^
          }
        } else if (o[keysO[i]] !== p[keysO[i]]) {
          //change !== to != for loose comparison
          return false; //not the same value
        }
      }
      return true;
    },
    reInitRecommendCarousel() {
      $(".js-tourism-recommend").each(function () {
        $(this).trigger("destroy.owl.carousel");
      });
      moduleTourism.init();
    },
    reInitTourCheapestDealsCarousel() {
      let obj = this;

      $(".js-tourism-cheapest_tour").each(function () {
        $(this).trigger("destroy.owl.carousel");
      });

      obj.tourCheapestDealsCarouselInit();
    },
    tourCheapestDealsCarouselInit() {
      let obj = this;

      $(".js-tourism-cheapest_tour").each(function () {
        $(this).owlCarousel({
          rtl: true,
          dots: false,
          responsive: {
            0: {
              items: 1,
            },
            768: {
              items: 2,
              margin: 20,
            },
            1366: {
              items: 4,
              margin: 20,
            },
          },
        });
        $(this)
          .closest(".tourism__cheapest_tour")
          .find(".category-slider__control_next")
          .off("click", obj.nextSlide__cheapest_tour)
          .on("click", obj.nextSlide__cheapest_tour);
        $(this)
          .closest(".tourism__cheapest_tour")
          .find(".category-slider__control_prev")
          .off("click", obj.prevSlide__cheapest_tour)
          .on("click", obj.prevSlide__cheapest_tour);
      });
    },
    nextSlide__cheapest_tour(e) {
      e.preventDefault();
      let currentTarget = e.currentTarget;
      $(currentTarget)
        .closest(".tourism__cheapest_tour")
        .find(".js-tourism-cheapest_tour")
        .trigger("next.owl.carousel");
    },
    prevSlide__cheapest_tour(e) {
      e.preventDefault();
      let currentTarget = e.currentTarget;
      $(currentTarget)
        .closest(".tourism__cheapest_tour")
        .find(".js-tourism-cheapest_tour")
        .trigger("prev.owl.carousel");
    },
    reInitNofshonCheapestDealsCarousel() {
      let obj = this;

      $(".js-tourism-cheapest_nofshon").each(function () {
        $(this).trigger("destroy.owl.carousel");
      });

      obj.nofshonCheapestDealsCarouselInit();
    },
    nofshonCheapestDealsCarouselInit() {
      let obj = this;

      $(".js-tourism-cheapest_nofshon").each(function () {
        $(this).owlCarousel({
          rtl: true,
          dots: false,
          responsive: {
            0: {
              items: 1,
            },
            768: {
              items: 2,
              margin: 20,
            },
            1366: {
              items: 4,
              margin: 20,
            },
          },
        });
        $(this)
          .closest(".tourism__cheapest_nofshon")
          .find(".category-slider__control_next")
          .off("click", obj.nextSlide__cheapest_nofshon)
          .on("click", obj.nextSlide__cheapest_nofshon);
        $(this)
          .closest(".tourism__cheapest_nofshon")
          .find(".category-slider__control_prev")
          .off("click", obj.prevSlide__cheapest_nofshon)
          .on("click", obj.prevSlide__cheapest_nofshon);
      });
    },
    nextSlide__cheapest_nofshon(e) {
      e.preventDefault();
      let currentTarget = e.currentTarget;
      $(currentTarget)
        .closest(".tourism__cheapest_nofshon")
        .find(".js-tourism-cheapest_nofshon")
        .trigger("next.owl.carousel");
    },
    prevSlide__cheapest_nofshon(e) {
      e.preventDefault();
      let currentTarget = e.currentTarget;
      $(currentTarget)
        .closest(".tourism__cheapest_nofshon")
        .find(".js-tourism-cheapest_nofshon")
        .trigger("prev.owl.carousel");
    },
    fetchFiltersOptions() {
      this.getDestination();
    },

    dropdownHelperInit: function () {
      $(".dropdown select[data-filter]")
        .off("change", this.dropdownChangeHandler)
        .on("change", this.dropdownChangeHandler);
    },
    dropdownHelperDestroy: function () {
      $(".dropdown select[data-filter]").off(
        "change",
        this.dropdownChangeHandler
      );
    },
    dropdownChangeHandler(e) {
      let obj = this;
      let $select = $(e.target);
      let val = $select.val();
      let filter = $select.attr("data-filter");
      if (
        typeof filter !== "undefined" &&
        typeof obj.filters[filter] !== "undefined" &&
        obj.filters[filter] != val
      ) {
        obj.filters[filter] = val;
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    filter_deal() {
      let obj = this;
      const start_date = obj.formatDate(obj.filters.dateRange.startDate);
      const end_date = obj.formatDate(obj.filters.dateRange.endDate);
      const params = {
        from: obj.filters.from,
        to: obj.filters.to,
        start_date: start_date,
        end_date: end_date,
        passengers: obj.filters.passengers,
        budget: obj.filters.budget,
        stars: obj.filters.stars,
      };
      obj.dealsSearchRan = true;
      obj.dealsSearchInProgress = true;
      obj.getDeal({ filters: params }).then(
        (deals_data) => {
          obj.dealsSearchInProgress = false;
          localStorage.setItem("passengers", obj.filters.passengers);
        },
        (error) => {
          obj.dealsSearchInProgress = false;
        }
      );
    },
  },
  updated() {},
};
</script>

<style lang="scss" scoped>
.tour-category-container {
  display: flex;
  padding: 30px 100px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1365px) {
    padding: 20px 10px;
  }
  .tourism__cheapest_tour,
  .tourism__cheapest_nofshon {
    margin-top: 55px;
  }
  .tourism__cheapest_tour-wrapper,
  .tourism__cheapest_nofshon-wrapper {
    position: relative;
  }
  .tourism__cheapest_tour-wrapper .category-slider__controls,
  .tourism__cheapest_nofshon-wrapper .category-slider__controls {
    top: 30px;
    bottom: 0;
    height: auto;
  }
  @media screen and (max-width: 1365px) {
    .tourism__cheapest_tour-wrapper .category__share,
    .tourism__cheapest_nofshon-wrapper .category__share {
      right: 42px;
    }
  }
  @media screen and (max-width: 1365px) {
    .tourism__cheapest_tour-wrapper .category__star,
    .tourism__cheapest_nofshon-wrapper .category__star {
      left: 42px;
    }
  }
  .tourism__cheapest_tour-slider,
  .tourism__cheapest_nofshon-slider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .tourism__cheapest_tour-slider.owl-loaded,
  .tourism__cheapest_nofshon-slider.owl-loaded {
    margin-top: -30px;
  }
  .tourism__cheapest_tour-slider .owl-stage-outer,
  .tourism__cheapest_nofshon-slider .owl-stage-outer {
    padding-top: 30px;
  }
  @media screen and (max-width: 767px) {
    .tourism__cheapest_tour,
    .tourism__cheapest_nofshon {
      margin-top: 35px;
    }
  }

  .category__share {
    z-index: 99;
  }
  .category__share-btn {
    position: initial;
  }
  .tourism-category__place-name {
    font-size: 20px;
  }
  .tourism-catefory-title {
    height: 44px;
    margin-bottom: 0;
  }
}
</style>
